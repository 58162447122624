import React from 'react'
import CountUp from 'react-countup'
import { PiStudentFill } from 'react-icons/pi'

const SectionTwo = () => {
  return (
    <>
      <div className='container pt-4'>
         <div className="row">
          <div className="col-md-2 course_section">
            <div className="row">
              <div className="col-sm-5">
                <PiStudentFill style={{fontSize:"40px"}}/>
              </div>
              <div className="col-sm-7">
                <div className="col">B
                  CA</div>
                <div className="col"><CountUp end={300} duration={10}/> +</div>
              </div>
            </div>
          </div>
          <div className="col-md-2 course_section">
            <div className="row">
              <div className="col-sm-5">
                <PiStudentFill style={{fontSize:"40px"}}/>
              </div>
              <div className="col-sm-7">
                <div className="col"><CountUp end={300} duration={10}/> +</div>
                <div className="col">2</div>
              </div>
            </div>
          </div>
          <div className="col-md-2 course_section">
            <div className="row">
              <div className="col-sm-5">
                <PiStudentFill style={{fontSize:"40px"}}/>
              </div>
              <div className="col-sm-7">
                <div className="col"><CountUp end={300} duration={10}/> +</div>
                <div className="col">2</div>
              </div>
            </div>
          </div>
          <div className="col-md-2 course_section">
            <div className="row">
              <div className="col-sm-5">
                <PiStudentFill style={{fontSize:"40px"}}/>
              </div>
              <div className="col-sm-7">
                <div className="col"><CountUp end={300} duration={10}/> +</div>
                <div className="col">2</div>
              </div>
            </div>
          </div>
         </div>
      </div>
    </>
  )
}

export default SectionTwo
