import React from "react";

const SectionOne = () => {
  return (
    <>
      <div className="container pt-3">
        <div className="row">
          <div className="col-md-6">
            <img
              src="../images/college_management.jpg"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-6">
            <h3>College management projcet</h3>
            <hr/>
            The College Management System is a comprehensive software application designed to streamline and automate the administrative processes of a college or university. It aims to enhance the efficiency and effectiveness of managing student data, faculty details, courses, departments, examinations, and other key functions within the institution. By leveraging modern technologies, this system minimizes the reliance on manual processes and improves the overall experience for students, faculty, and administrative staff.</div>
        </div>
      </div>
    </>
  );
};

export default SectionOne;
