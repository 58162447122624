import React from 'react'
import SectionOne from './SectionOne'
import SectionTwo from './SectionTwo'

const Home = () => {
  return (
    <>
    <SectionOne/>
    <SectionTwo/>
    </>
  )
}

export default Home
