import React from 'react'
import { IoIosLogIn } from 'react-icons/io'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">College management</a>
  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav">
      <Link class="nav-item nav-link active" to="/">Home</Link>
      <a class="nav-item nav-link active" href="#">About us</a>
      <a class="nav-item nav-link active" href="#">Department</a>
      <a class="nav-item nav-link active" href="#">Events</a>
      <Link class="nav-item nav-link active" to="contactUs">Contact us</Link>
      <Link class="nav-item nav-link active" to="login"><IoIosLogIn />
       Login</Link>
    </div>
  </div>
</nav>
    </>
  )
}

export default Header
