import React from "react";
import { MdEmail, MdLock } from "react-icons/md";

const Login = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6">Image</div>
          <div className="col-md-6">
            <div>
              <h3>Login</h3>
              <form action="" className="login_style">
                <div className="input_div">
                  <MdEmail style={{marginRight:"10px"}}/>
                  <input type="email" placeholder="Email" name="" id="" />
                </div>
                <div className="input_div">
                  <MdLock style={{marginRight:"10px"}}/>
                  <input type="password" placeholder="password" name="" id="" />
                </div>
                <button className="submit_btn">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
