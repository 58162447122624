import React from "react";
import { IoHomeOutline } from "react-icons/io5";

const ContactHs = () => {
  return (
    <>
      <div className="container">
        <div className="row p-2">
          <div className="col-md-6">
            <div className="row">
              <h3>Reach Us</h3>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <IoHomeOutline
                      style={{ color: "rgb(12, 123, 192)", fontSize: "35px" }}
                    />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3 className="contact_headings">Address</h3>
                      <p style={{ fontSize: "14px" }}>
                        1245 Whitehorse Mercerville Road, Suite 410-411,
                        Hamilton, New Jersey-08619
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <IoHomeOutline
                      style={{ color: "rgb(12, 123, 192)", fontSize: "35px" }}
                    />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3 className="contact_headings">Phone</h3>
                      <p style={{ fontSize: "14px" }}>+1 609 450 3535</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <IoHomeOutline
                      style={{ color: "rgb(12, 123, 192)", fontSize: "35px" }}
                    />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3 className="contact_headings">Mail Address</h3>
                      <p style={{ fontSize: "14px" }}>lokeshrewar@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-4">
                    <IoHomeOutline
                      style={{ color: "rgb(12, 123, 192)", fontSize: "35px" }}
                    />
                  </div>
                  <div className="col-8">
                    <div className="row">
                      <h3 className="contact_headings">Working Hours</h3>
                      <p style={{ fontSize: "14px" }}>
                        Mon to Fri : 8am to 5pm EST
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <form action="" className="contact_from">
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" />
              <input type="text" placeholder="Phone Number" />
              <input type="email" placeholder="Email" />
              <textarea placeholder="Message" rows={5} />
              <button type="submit" className="submit_btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.2835969693265!2d75.17206479437078!3d27.584735943515778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396cbca16b73edb9%3A0xa11f399100e01e19!2sShekhawati%20College%2C%20Sikar!5e0!3m2!1sen!2sin!4v1733477005406!5m2!1sen!2sin" width="600" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactHs;
